<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Merchandising from "@/services/Merchandising";
import Http from "@/services/Http";
import Swal from "sweetalert2";
import TabProductTags from "@/components/tabs/tabProductTags";
/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, TabProductTags },
  page: {
    title: "Product details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Product details",
      items: [
        {
          text: "Products",
          href: "/",
        },
      ],
      showFullDescription: false,
      descLines: [],
      isCollapsed: true,
      filterData: false,
      isBusy: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "Art_Sifra",
      product_id: this.$route.params.id,
      sortDesc: true,
      productInfo: {
        Art_ID: '',
        Brend: '',
        Art_ImeKratko: '',
        Art_Sifra: '',
        Opis: '',
        Art_CenaP1: '',
        Art_CenaP3: '',
        Lager_Kolicina: '',
        Grupa: '',
        PodGrupa: '',
        PodPodGrupa: '',
        PopustDo: '',
        PopustOd: '',
        Garancija: '',
        CenaAkcija: '',
        image: '',
      },
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    iconClass() {
      return this.isCollapsed ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down';
    },

    productDescLines() {
      console.log("desc", this.productInfo.Opis.trim().split('\n'));
      const lines = this.productInfo.Opis.trim().split('\n');
      return lines.filter(line => line.trim() !== '');

    },
  },
  mounted() {
  },
  async created() {
    this.getProduct();
    this.getCategories();
    this.getTags();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },

    async getProduct() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getProduct(this.product_id)
        console.log(response.data.data);
        this.productInfo = response.data.data;
        console.log('info', this.productInfo);
        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = [];
      }
    },
    async getCategories() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getCategories()
        console.log(response.data.data);
        this.categories = response.data.data
        console.log("kategorii", this.categories)

        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    async getTags() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getTags()
        console.log(response.data.data);
        this.tags = response.data.data
        console.log("tags", this.tags)

        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },

    refreshTable() { },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Match added!`,
        showConfirmButton: false,
        timer: 2000,
      });
      this.getProducts();
      this.getCategories();
    },
    async success() {
      this.getProduct();
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4 p-3">
      <div class="col-xl-4">
        <div class="card mh-50">
          <div class="card-body">
            <div class="text-center">
              <div class="d-flex justify-content-center">
                <img
                  :src="productInfo.image ? productInfo.image : 'https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png'"
                  class="card-img-top d-block w-50 h-50" alt="">
              </div>
            </div>

            <hr class="my-2" />

            <div class="table-responsive mt-4 mb-0">
              <h5 class="text-primary">Product Information</h5>
              <div class="mt-3">
                <p class="mb-2">
                  <span class="text-strong">Product Name : </span>
                  <span class="font-size-16">{{ productInfo.Art_ImeKratko }}</span>
                </p>
              </div>

              <div class="mt-2">
                <p class="">
                  <span class="text-strong">Product ID : </span>
                  <span class="font-size-16">{{ productInfo.Art_ID }}</span>
                </p>
              </div>
              <hr>
              <div class="">
                <p class="mb-2">
                  <span class="text-strong">Category : </span>
                  <span class="font-size-16">{{ productInfo.Grupa }} / {{ productInfo.PodGrupa }} /
                    {{ productInfo.PodPodGrupa }}</span>
                </p>
              </div>

              <div class="mt-2">
                <p class="mb-2">
                  <span class="text-strong">Brand : </span>
                  <span class="font-size-16">{{ productInfo.Brend }}</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2">
                  <span class="text-strong">Warranty : </span>
                  <span class="font-size-16" v-if="productInfo.Garancija > 0">{{ productInfo.Garancija }} months</span>
                  <span class="font-size-16" v-else>No Warranty</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2 font-size-16">
                  <span class="text-strong">Regular Price : </span>
                  <span class="font-size-20">{{ productInfo.Art_CenaP1.split(".")[0] + ' ден' }}</span>
                </p>
              </div>
              <div class="mt-2">
                <p class="mb-2 font-size-16">
                  <span class="text-strong ">Club Price : </span>
                  <span class="text-primary font-size-20">{{ productInfo.Art_CenaP3.split(".")[0] + ' ден' }}</span>
                </p>
              </div>
              
              <div class="mt-2 d-flex justify-content-end ">
                <p class="mb-2 font-size-18">
                  <span class="text-strong ">Stock : </span>
                  <span class="font-size-18 text-primary">{{ productInfo.Lager_Kolicina }}</span>
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="card mb-0">
          <b-tabs content-class="p-4" justified class="nav-tabs-custom">
            <b-tab active>
              <template v-slot:title>
                <i class="uil uil-list-ui-alt font-size-20"></i>
                <span class="d-none d-sm-block">Description</span>
              </template>
              <template>
                <div class="row">
                  <div class="col-sm-12 col-md-8">
                    <div class="mb-2 p-2">
                      <p class="mb-1 text-strong" v-if="productDescLines.length > 0">Description of product:</p>
                      <p v-else>No description for this product</p>
                      <ul class="lh-lg list-unstyled">
                        <li
                          v-for="(line, index) in productDescLines.slice(0, showFullDescription ? productDescLines.length : 4)"
                          :key="index"><i class="mdi mdi-minus text-primary"></i> {{ line }}</li>
                      </ul>

                      <button @click="toggleDescription" class="btn btn-primary btn-sm"
                        v-if="productDescLines.length > 3">
                        {{ showFullDescription ? 'Read Less' : 'Read More' }}
                      </button>
                    </div>
                  </div>
                </div>
               
              </template>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <i class="uil uil-ticket font-size-20"></i>
                <span class="d-none d-sm-block">Tags</span>
              </template>
              <div>
                <div class="row">
                  <TabProductTags :product_id=this.product_id />
                </div>
              </div>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>