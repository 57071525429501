import Http from "./Http";

export default {

async getCategories(){
    return await Http.get(`/merchandise/categories`);
},
async getTags(){
    return await Http.get(`/merchandise/tags`);
},
async getProducts(params = '') {
    return await Http.get(`/merchandise/products?${params}`)
},
async getProduct(product_id) {
    return await Http.get(`/merchandise/products/${product_id}`)
},
async getTagsByProduct(product_id) {
    return await Http.get(`/merchandise/products/${product_id}/tags`)
},
async attachTag(product_id, tag_id) {
    return await Http.post(`/merchandise/product/${product_id}/tag/attach`, {
      tag_id: tag_id,
    });
  },
async detachTag(product_id, tag_id) {
    return await Http.post(`/merchandise/product/${product_id}/tag/detach`, {
      tag_id: tag_id,
    });
  },
async getMedia(params = '') {
    return await Http.get(`/merchandise/media`)
},
async updateMedia(data) {
    return await Http.post(`/merchandise/media`, data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
},
async getPromotions(params = '') {
    return await Http.get(`/merchandise/product/promo`)
},
async getBestSeller(params = '') {
    return await Http.get(`/merchandise/product/bestseller`)
},
async removePromotion(id) {
    return await Http.delete(`/merchandise/product/promo?product_id=${id}`)
},
async removeBestSeller(id) {
    return await Http.delete(`/merchandise/product/bestseller?product_id=${id}`)
},
async getProductsAutocomplete(params = '') {
    return await Http.get(`/merchandise/products/autocomplete?${params}`)
},
async addPromotion(id) {
    return await Http.post(`/merchandise/product/promo?product_id=${id}`)
},
async addBestSeller(id) {
    return await Http.post(`/merchandise/product/bestseller?product_id=${id}`)
},

};