<script>
import Merchandising from "@/services/Merchandising";
import Swal from "sweetalert2";

export default {
  props: {
    product_id: String
  },
  data() {
    return {
      productTags: [],
      tags: []
    };
  },
  computed: {
    filteredTags() {
      return this.tags.filter(tag => !this.productTags.some(productTag => productTag.id === tag.id));
    }
  },
  async created() {

    this.getTagsByProduct();
    this.getTags();
  },
  methods: {
    async getTagsByProduct() {
      try {
        const response = await Merchandising.getTagsByProduct(this.product_id);
        if (response.data.success) {
          this.productTags = response.data.data;
        } else {
          console.error("Error fetching product tags:", response.data.data.message);
        }
      } catch (error) {
        console.error("Error fetching product tags:", error);
      }
    },
    async getTags() {
      try {
        const response = await Merchandising.getTags();
        this.tags = response.data.data;
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    async attachTag(tag_id) {
      try {

        this.productTags.push(this.tags.find(tag => tag.id === tag_id));
        
        const response = await Merchandising.attachTag(this.product_id, tag_id);
        if (response.data.success) {

          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: response.data.data.message,
            confirmButtonColor: "#5B73E7",
          });
        } else {
          console.error("Error attaching tag:", response.data.data.message);
        }
      } catch (error) {
        console.error("Error attaching tag:", error);
      }
    },
    async detachTag(tag_id) {
      try {

        this.productTags = this.productTags.filter(tag => tag.id !== tag_id);
        
        const response = await Merchandising.detachTag(this.product_id, tag_id);
        if (response.data.success) {

          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: response.data.data.message,
            confirmButtonColor: "#5B73E7",
          });
        } else {
          console.error("Error detaching tag:", response.data.data.message);
        }
      } catch (error) {
        console.error("Error detaching tag:", error);
      }
    },
    async success() {
      this.getTagsByProduct();
      this.getTags();
    },
  }
};
</script>
<template>
    <div>
        <div v-if="productTags.length > 0">
            <p class="mb-1 text-strong font-size-18">Product Tags</p>
            <div v-for="tag in productTags" :key="tag.id">
                <div class="d-flex align-items-center mb-2">
                    {{ tag.tag_name }}
                    <i class="mdi mdi-minus text-danger mdi-18px btn" @click="detachTag(tag.id)"></i>
                </div>
            </div>
            <hr>
        </div>

        <p v-if="filteredTags.length > 0" class="mb-1 text-strong mb-1 font-size-18">Available Tags</p>
        <div v-for="tag in filteredTags" :key="tag.id">
            <div class="d-flex align-items-center mb-2">
                {{ tag.tag_name }}
                <i class="mdi mdi-plus text-primary mdi-18px btn" @click="attachTag(tag.id)"></i>
            </div>
        </div>
    </div>
</template>
 
  
  
  
  
  
  